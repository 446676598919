<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['localAirQuality'])">
      <single-answer-question
        id="local-air-quality"
        title="label.localAirQuality"
        :value="localAirQuality"
        :options="$options.localAirQualityOptions"
        dysfunction="oxidative-stress"
        @input="onFieldChange('localAirQuality', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap
      class="q-mt24 q-mt32-md"
      :error="getFieldsError(['usualEnvironment'])"
    >
      <multi-answer-question
        id="usual-environment"
        title="label.usualEnvironment"
        :value="usualEnvironment"
        :options="$options.usualEnvironmentOptions"
        dysfunction="oxidative-stress"
        @input="onFieldChange('usualEnvironment', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import MultiAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/multi-answer-question/MultiAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { AIR_QUALITY, USUAL_ENVIRONMENT } from '@/modules/questionnaire/api/constants';

const LOCAL_AIR_QUALITY_OPTIONS = [
  { value: AIR_QUALITY.CLEAN, text: 'airQuality.clean' },
  { value: AIR_QUALITY.SLIGHTLY_POLLUTED, text: 'airQuality.slightlyPolluted' },
  { value: AIR_QUALITY.POLLUTED, text: 'airQuality.polluted' },
  { value: AIR_QUALITY.VERY_POLLUTED, text: 'airQuality.veryPolluted' }
];

const USUAL_ENVIRONMENT_OPTIONS = [
  { value: USUAL_ENVIRONMENT.OUTSIDE, text: 'usualEnvironment.outside' },
  { value: USUAL_ENVIRONMENT.IN_THE_SUN, text: 'usualEnvironment.inTheSun' },
  { value: USUAL_ENVIRONMENT.INSIDE, text: 'usualEnvironment.inside' },
  { value: USUAL_ENVIRONMENT.AIR_CONDITIONED, text: 'usualEnvironment.airConditioned' }
];

export default {
  name: 'EnvironmentTemplate',
  components: { MultiAnswerQuestion, SingleAnswerQuestion, QuestionWithErrorWrap },
  mixins: [stepTemplateMixin],
  localAirQualityOptions: LOCAL_AIR_QUALITY_OPTIONS,
  usualEnvironmentOptions: USUAL_ENVIRONMENT_OPTIONS,
  props: {
    localAirQuality: {
      type: String,
      required: true
    },
    usualEnvironment: {
      type: Array,
      required: true
    }
  }
};
</script>
